
<template>
  <v-container>
    <v-row>
      <v-col cols="2" class="pwo-center-vertical">
        <li>id: {{ carId }}</li>
      </v-col>
      <v-col cols="1" class="pwo-center-vertical">
        <div style="width: 100%; text-align: right">offset:</div>
      </v-col>
      <v-col cols="1" class="pwo-center-vertical">
        <v-text-field
          @input="updateLocalOffset"
          :value="newDirectionOffset"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="2" class="pwo-center-vertical">
        <v-btn @click="saveDirectionOffset()">save offset</v-btn>
        <v-icon
          large
          color="green"
          :style="'display:' + (displaySuccess ? 'flex' : 'none')"
        >
          mdi-check
        </v-icon>
        <v-icon
          large
          color="red"
          :style="'display:' + (displayFailure ? 'flex' : 'none')"
        >
          mdi-alert-circle
        </v-icon>
      </v-col>
      <v-col cols="1" class="pwo-center-vertical">
        <div style="width: 100%; text-align: right">max speed:</div>
      </v-col>
      <v-col cols="1" class="pwo-center-vertical">
        <v-text-field
          @input="updateLocalMaxSpeed"
          :value="newMaxSpeed"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="2" class="pwo-center-vertical">
        <v-btn @click="saveMaxSpeed()">save speed</v-btn>
        <v-icon
          large
          color="green"
          :style="'display:' + (displaySuccess ? 'flex' : 'none')"
        >
          mdi-check
        </v-icon>
        <v-icon
          large
          color="red"
          :style="'display:' + (displayFailure ? 'flex' : 'none')"
        >
          mdi-alert-circle
        </v-icon>
      </v-col>
    </v-row>
  </v-container>
</template>


<style scoped>
.pwo-center-vertical {
  display: flex;
  align-items: center;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>



<script>
import gql from "graphql-tag";

export default {
  name: "DirectionOffset",
  props: ["carId", "directionOffset"],
  data: () => ({
    newDirectionOffset: 0,
    newMaxSpeed: 0,
    displaySuccess: false,
    displayFailure: false,
  }),
  mounted: function () {
    this.newDirectionOffset = this.directionOffset;
  },
  methods: {
    async saveDirectionOffset() {
      console.log(
        `car ${this.carId} - setDirectionOffset ${this.newDirectionOffset}`
      );
      try {
        const result = await this.$apolloClient.mutate({
          mutation: gql`
            mutation DirectionOffset($directionOffset: DirectionOffsetInput) {
              directionOffset(directionOffset: $directionOffset)
            }
          `,
          variables: {
            directionOffset: {
              carId: parseInt(this.carId),
              offset: parseFloat(this.newDirectionOffset),
            },
          },
        });
        console.log(`set direction offset ${result}`);
        this.displaySuccess = true;
        setTimeout(() => (this.displaySuccess = false), 2000);
      } catch (e) {
        console.log(`error in saveDirectionOffset ${JSON.stringify(e)}`);
        this.displayFailure = true;
        setTimeout(() => (this.displayFailure = false), 2000);
      }
    },
    async saveMaxSpeed() {
      console.log(`car ${this.carId} - setMaxSpeed ${this.newMaxSpeed}`);
      try {
        const result = await this.$apolloClient.mutate({
          mutation: gql`
            mutation MaxSpeed($maxSpeed: MaxSpeedInput) {
              maxSpeed(maxSpeed: $maxSpeed)
            }
          `,
          variables: {
            maxSpeed: {
              carId: parseInt(this.carId),
              maxspeed: parseFloat(this.newMaxSpeed),
            },
          },
        });
        console.log(`set max speed ${result}`);
        this.displaySuccess = true;
        setTimeout(() => (this.displaySuccess = false), 2000);
      } catch (e) {
        console.log(`error in saveMaxSpeed ${JSON.stringify(e)}`);
        this.displayFailure = true;
        setTimeout(() => (this.displayFailure = false), 2000);
      }
    },
    updateLocalOffset(val) {
      this.newDirectionOffset = val;
    },
    updateLocalMaxSpeed(val) {
      this.newMaxSpeed = val;
    },
  },
};

</script>
